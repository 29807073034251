var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$lang("summaryHealthCheckTable.tableName"))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.provideTableData,"loading":_vm.isSummaryLoading || _vm.isSummaryUpdateLoading,"search":_vm.search,"items-per-page":-1,"custom-sort":_vm.customSort,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$lang('components.search'),"append-icon":"mdi-magnify","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),(_vm.hasMetricWeightAccess)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","fab":"","color":"primary","to":{
                  name: _vm.AppSection.HEALTHCHECK_METRIC_WEIGHT,
                }}},on),[_c('v-icon',[_vm._v("mdi-weight")])],1)]}}],null,false,436565893)},[_c('span',[_vm._v(" "+_vm._s(_vm.$lang("summaryHealthCheckTable.metricWeightsButton"))+" ")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3",attrs:{"small":"","fab":"","loading":_vm.isThirdPartyUpdateLoading,"disabled":_vm.isThirdPartyUpdateLoading,"color":"primary"},on:{"click":_vm.updateThirdParty}},on),[_c('v-icon',[_vm._v("mdi-cached")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$lang("summaryHealthCheckTable.updateThirdParty"))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3",attrs:{"small":"","fab":"","loading":_vm.isParsingUpdateLoading,"disabled":_vm.isParsingUpdateLoading,"color":"primary"},on:{"click":_vm.updateParsing}},on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$lang("summaryHealthCheckTable.updateParsing"))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3",attrs:{"small":"","fab":"","loading":_vm.isSummaryUpdateLoading,"disabled":_vm.isSummaryUpdateLoading,"color":"primary"},on:{"click":_vm.updateTableData}},on),[_c('v-icon',[_vm._v("mdi-database-refresh")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$lang("summaryHealthCheckTable.updateDataflowsButton"))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3",attrs:{"loading":_vm.isHealthCheckSegmentsUpdating,"disabled":_vm.isHealthCheckSegmentsUpdating,"color":"primary","small":"","fab":""},on:{"click":_vm.updateSegments}},on),[_c('v-icon',[_vm._v("mdi-account-convert")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$lang("summaryHealthCheckTable.updateSegmentsButton")))])])],1)]},proxy:true},{key:"item.application",fn:function(ref){
                var item = ref.item;
return [_c('health-check-summary-app-menu',{attrs:{"appId":item.application}})]}},{key:"item.twoDaysStat",fn:function(ref){
                var item = ref.item;
return [_c('td',{staticClass:"text-center",style:(_vm.getStyleByScoreSeverity(item.metricsForPeriods[0].scoreSeverity))},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.metricsForPeriods[0].summaryScore)+" ")])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getTooltipMessage(item.metricsForPeriods[0]))}})])],1)]}},{key:"item.sevenDaysStat",fn:function(ref){
                var item = ref.item;
return [_c('td',{staticClass:"text-center",style:(_vm.getStyleByScoreSeverity(item.metricsForPeriods[1].scoreSeverity))},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.metricsForPeriods[1].summaryScore)+" ")])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getTooltipMessage(item.metricsForPeriods[1]))}})])],1)]}},{key:"item.fourteenDaysStat",fn:function(ref){
                var item = ref.item;
return [_c('td',{staticClass:"text-center",style:(_vm.getStyleByScoreSeverity(item.metricsForPeriods[2].scoreSeverity))},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.metricsForPeriods[2].summaryScore)+" ")])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getTooltipMessage(item.metricsForPeriods[2]))}})])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }