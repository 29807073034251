
















































































































































































































import { Component, Vue } from "vue-property-decorator";

import HealthCheckSummaryAppMenu from "@/healthcheck/components/HealthCheckSummaryAppMenu.vue";
import {
  MetricsForPeriod,
  ScoreSeverity,
  SummaryResponseModel,
} from "@/healthcheck/model/SummaryResponseModel";
import { AppSection, CurrentUserModel } from "@/shared/models";

@Component({ components: { HealthCheckSummaryAppMenu } })
export default class HealthCheckSummaryView extends Vue {
  readonly br = "<br>";
  readonly empty = "";
  readonly headers = [
    {
      text: this.$lang("summaryHealthCheckTable.headers.application"),
      align: "start",
      value: "application",
    },
    {
      text: this.$lang("summaryHealthCheckTable.headers.twoDaysStat"),
      align: "center",
      value: "twoDaysStat",
    },
    {
      text: this.$lang("summaryHealthCheckTable.headers.sevenDaysStat"),
      align: "center",
      value: "sevenDaysStat",
    },
    {
      text: this.$lang("summaryHealthCheckTable.headers.fourteenDaysStat"),
      align: "center",
      value: "fourteenDaysStat",
    },
  ];
  readonly AppSection = AppSection;

  search = "";

  get provideTableData(): Array<SummaryResponseModel> {
    return this.$store.state.healthCheckStore.summaryHealthCheckTables;
  }

  get isSummaryLoading(): boolean {
    return this.$store.state.healthCheckStore.isSummaryLoading;
  }

  get isThirdPartyUpdateLoading(): boolean {
    return this.$store.state.healthCheckStore.isThirdPartyUpdateLoading;
  }

  get isParsingUpdateLoading(): boolean {
    return this.$store.state.healthCheckStore.isParsingUpdateLoading;
  }

  get isSummaryUpdateLoading(): boolean {
    return this.$store.state.healthCheckStore.isSummaryUpdateLoading;
  }

  get isHealthCheckSegmentsUpdating(): boolean {
    return this.$store.state.healthCheckStore.isHealthCheckSegmentsUpdating;
  }

  get currentUser(): CurrentUserModel {
    return this.$store.state.userStore.currentUser;
  }

  get applicationId(): string {
    return this.$store.state.application.applicationId;
  }

  get hasMetricWeightAccess(): boolean {
    return !!this.currentUser.createAccessEntities[
      this.applicationId
    ]?.includes(AppSection.HEALTHCHECK_METRIC_WEIGHT);
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("summaryHealthCheckTable.title")
    );

    this.$store.dispatch("getHealthCheckData");
  }

  getMetricsForPeriodsIndexByDaysStat(daysStat: string): number | undefined {
    return new Map([
      ["twoDaysStat", 0],
      ["sevenDaysStat", 1],
      ["fourteenDaysStat", 2],
    ]).get(daysStat);
  }

  customSort(
    items: Array<SummaryResponseModel>,
    [index]: Array<string>,
    [isDesc]: Array<boolean>
  ): Array<SummaryResponseModel> {
    const sortOrder = isDesc ? -1 : 1;

    items.sort((a: SummaryResponseModel, b: SummaryResponseModel) => {
      if (
        ["twoDaysStat", "sevenDaysStat", "fourteenDaysStat"].includes(index)
      ) {
        return a.metricsForPeriods[
          this.getMetricsForPeriodsIndexByDaysStat(index) as number
        ].summaryScore <
          b.metricsForPeriods[
            this.getMetricsForPeriodsIndexByDaysStat(index) as number
          ].summaryScore
          ? -sortOrder
          : sortOrder;
      } else {
        return b[index as keyof SummaryResponseModel] <
          a[index as keyof SummaryResponseModel]
          ? -sortOrder
          : sortOrder;
      }
    });

    return items;
  }

  getStyleByScoreSeverity(scoreSeverity: string): string {
    let color: string;
    switch (scoreSeverity) {
      case ScoreSeverity.LOW:
        color = "#8BC34AB2";
        break;
      case ScoreSeverity.MEDIUM:
        color = "#FFEB3BB2";
        break;
      case ScoreSeverity.HIGH:
        color = "#F44336B2";
        break;
      default:
        color = "transparent";
    }
    return `background: ${color};`;
  }

  getTooltipMessage(item: MetricsForPeriod): string {
    return (
      this.getDataFlowTooltipMessage(item) +
      this.br +
      this.getParsingTooltipMessage(item) +
      this.br +
      this.getSegmentTooltipMessage(item) +
      this.br +
      this.getThirdPartyTooltipMessage(item)
    );
  }

  private getThirdPartyTooltipMessage(item: MetricsForPeriod): string {
    let metrics = this.empty;
    if (item.thirdPartyMetrics.length) {
      metrics = item.thirdPartyMetrics
        .map(
          ({ metric, count }) =>
            `${metric.toString().toLowerCase()} - ${count}`,
          []
        )
        .join(this.br);
    }
    return this.buildTooltipMessage(
      this.$lang("summaryHealthCheckTable.tooltip.thirdPartyHeader"),
      item.thirdPartyScore,
      metrics
    );
  }

  private getDataFlowTooltipMessage(item: MetricsForPeriod): string {
    let metrics = this.empty;
    if (item.dataFlowMetrics.length) {
      metrics = item.dataFlowMetrics
        .map(({ dataFlow, metrics }) => {
          const metricsMapped = metrics.map(
            (metric: Record<string, number>) => {
              const [key, value] = Object.entries(metric)[0];

              return `${key.toLowerCase()} - ${value}`;
            }
          );
          const metricsString = metricsMapped.join(this.br);

          return `${dataFlow}:${this.br}${metricsString}${this.br}`;
        })
        .join(this.br);
    }
    return this.buildTooltipMessage(
      this.$lang("summaryHealthCheckTable.tooltip.dataFlowHeader"),
      item.dataFlowScore,
      metrics
    );
  }

  private getParsingTooltipMessage(item: MetricsForPeriod): string {
    let metrics = this.empty;
    if (item.parsingMetrics.length) {
      metrics = item.parsingMetrics
        .map(
          ({ metric, maxPercentDelta }) =>
            `${metric.toLowerCase()} - ${maxPercentDelta}%`,
          []
        )
        .join(this.br);
    }
    return this.buildTooltipMessage(
      this.$lang("summaryHealthCheckTable.tooltip.parsingHeader"),
      item.parsingScore,
      metrics
    );
  }

  private getSegmentTooltipMessage(item: MetricsForPeriod): string {
    let metrics = this.empty;
    if (item.segmentMetrics.length) {
      metrics = item.segmentMetrics
        .map(
          ({ metric, count }) =>
            `${metric.toString().toLowerCase()} - ${count}`,
          []
        )
        .join(this.br);
    }
    return this.buildTooltipMessage(
      this.$lang("summaryHealthCheckTable.tooltip.segmentHeader"),
      item.segmentScore,
      metrics
    );
  }

  buildTooltipMessage(header: string, score: number, metrics: string): string {
    const messagePart = `${header}  (${score} pts) :`;
    if (!metrics) {
      return messagePart + " OK";
    } else {
      return messagePart + this.br + metrics;
    }
  }

  updateThirdParty() {
    this.$store.dispatch("postThirdPartyUpdate");
  }

  updateParsing() {
    this.$store.dispatch("postParsingUpdate");
  }

  updateTableData() {
    this.$store.dispatch("postDataFlowShortUpdate");
  }

  updateSegments() {
    this.$store.dispatch("updateHealthCheckSegments");
  }
}
