
























import { Component, Prop, Vue } from "vue-property-decorator";

import { AppSection } from "@/shared/models";

@Component
export default class HealthCheckSummaryAppMenu extends Vue {
  @Prop() appId!: string;

  get applicationId(): string {
    return this.$store.state.application.applicationId;
  }

  get menuItems(): Array<AppSection> {
    const appSections =
      this.$store.state.userStore.currentUser.viewAccessEntities[
        this.applicationId
      ];

    return [
      ...(appSections.includes(AppSection.HEALTHCHECK_DATA_FLOW)
        ? [AppSection.HEALTHCHECK_DATA_FLOW]
        : []),
      ...(appSections.includes(AppSection.HEALTHCHECK_PARSING)
        ? [AppSection.HEALTHCHECK_PARSING]
        : []),
    ];
  }
}
